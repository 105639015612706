<template>
	<div class="container">
		<Navbar />

		<img :src="image">
	</div>
</template>


<script>
	import Navbar from "@/components/Navbar";

	export default {
		name: "Image",
		components: {Navbar},
		computed: {
			image() {
				switch (this.$route.params.image.toLowerCase()) {
					case "chat": {
						return require("@/assets/img/fake/under_construction_beta.png");
						break;
					}
					case "streams": {
						return require("@/assets/img/fake/under_construction_beta.png");
						break;
					}
					case "notifications": {
						return require("@/assets/img/fake/under_construction_beta.png");
						break;
					}
					default: {
						return "";
					}
				}
			}
		}
	}
</script>


<style lang="scss" scoped>
	.container {
		height: auto;

		background-color: #40362e;

		img {
			@include center;
			display: block;

			max-width: 100%;
			object-fit: cover;
		}
	}
</style>

